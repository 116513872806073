// const mapEnvironment = {
//   localhost: {
//     AUTH: "https://id-dev.zipcondominio.com.br",
//     API: "https://data-dev.zipcondominio.com.br",
//     PORTAL: "https://portal-dev.zipcondominio.com.br",
//   },
//   "cadastro-dev.zipcondominio.com.br": {
//     AUTH: "https://id-dev.zipcondominio.com.br",
//     API: "https://data-dev.zipcondominio.com.br",
//     PORTAL: "https://portal-dev.zipcondominio.com.br",
//   },
//   "cadastro-hmg.zipcondominio.com.br": {
//     AUTH: "https://id-hmg.zipcondominio.com.br",
//     API: "https://data-hmg.zipcondominio.com.br",  
//     PORTAL: "https://portal-hmg.zipcondominio.com.br",
//   },
//   "cadastro.zipcondominio.com.br": {
//     AUTH: "https://id.zipcondominio.com.br",
//     API: "https://data.zipcondominio.com.br",  
//     PORTAL: "https://portal.zipcondominio.com.br",
//   },
// };

export default {
  appId: process.env.REACT_APP_APPID,
  appKey: process.env.REACT_APP_APPKEY,
  username: process.env.REACT_APP_USERNAME,
  password: process.env.REACT_APP_PASSWORD, 
  storageAuthName: "AuthCadastroZIP",
  baseAuthURL: process.env.REACT_APP_AUTH,
  baseURL: process.env.REACT_APP_API,
  appIdPortal: process.env.REACT_APP_APPIDPORTAL,
  appKeyPortal: process.env.REACT_APP_APPKEYPORTAL,
  urlPortal: process.env.REACT_APP_URLPORTAL,
};
